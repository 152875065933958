import React from 'react';
import {Link} from 'react-router-dom';

export default class FooterOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer class="footer-one">
                    <div class="footer-one__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/footer-v1-shape3.png)'}}></div>
                    <div class="shape1 float-bob-y"><img src={publicUrl+"assets/images/shapes/footer-v1-shape1.png"} alt="#" /></div>
                    <div class="shape2 float-bob-y"><img src={publicUrl+"assets/images/shapes/footer-v1-shape2.png"} alt="#" /></div>
                    {/* Start Footer */}
                    <div class="footer">
                        <div class="container">
                            <div class="row justify-content-center">
                                {/* Start Footer One Right */}
                                <div class="col-xl-12">
                                    <div class="footer-one__right">
                                        <div class="row justify-content-between">
                                            {/* Start Footer One Right Single */}
                                            <div class="col-xl-4 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.2s">
                                                <div class="footer-one__right-single mb50">
                                                    <div class="title">
                                                        <h2>Components</h2>
                                                    </div>
                                                    <div class="footer-one__right-single-services">
                                                        <ul class="footer-one__right-single-list">
                                                            <li>Admin Dashboard</li>
                                                            <li>Customer Website</li>
                                                            <li>Customer App</li>
                                                            <li>Courier App</li>
                                                            <li>Farm App</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}

                                            {/* Start Footer One Right Single */}
                                            <div class="col-xl-4 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div class="footer-one__right-single mb50">
                                                    <div class="title">
                                                        <h2>Legal</h2>
                                                    </div>
                                                    <div class="footer-one__right-single-links">
                                                        <ul class="footer-one__right-single-list">
                                                            <li>Terms Of Use</li>
                                                            <li>Privacy Policy</li>
                                                            <li>Data Policy</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}

                                            {/* Start Footer One Right Single */}
                                            <div class="col-xl-4 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.4s">
                                                <div class="footer-one__right-single">
                                                    <div class="title">
                                                        <h2>Contact</h2>
                                                    </div>
                                                    <div class="footer-one__right-single-contact">
                                                        <p> <a href="mailto:cebomagagula@gmail.com">contact@agrichub.co.za</a>
                                                            <br /> 55B Thrush Trail Road, <br />Birdswood, <br />Richards Bay, <br />3900</p>
                                                        <a href="mailto:cebomagagula@gmail.com">info@agrichub.co.za</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}
                                        </div>

                                        <div class="footer-one__right-bottom wow animated fadeInUp" data-wow-delay="0.1s">
                                            <ul class="social-links">
                                                <li><Link to={process.env.PUBLIC_URL + `/`}><span class="icon-twittera"></span></Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/`}><span class="icon-facebooka"></span></Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/`}><span class="icon-pinteresta"></span></Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + `/`}><span class="icon-instagrama"></span></Link></li>
                                            </ul>

                                            <div class="footer-one__right-bottom-contact">
                                                <div class="icon-box">
                                                    <span class="icon-phone-call"></span>
                                                </div>
                                                <div class="content-box">
                                                    <p>Call Anytime</p>
                                                    <h4><a href="tel:27767725684">(+27) 76 772 5684</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Footer One Right */}
                            </div>
                        </div>
                    </div>

                    <div class="footer-one__bottom">
                        <div class="container">
                            <div class="bottom-inner">
                                <div class="copyright">
                                    <p>Copyright © 2024 All Rights Reserved.</p>
                                </div>

                                <ul class="footer-one__bottom-menu">
                                    <li><b>Thumbela Communications (Pty) Ltd</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}
