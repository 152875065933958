import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import FooterOne from '../../common/footer/FooterOne';

const SignUpForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        user_type: ''
    });

    const [status, setStatus] = useState({
        submitting: false,
        success: false,
        error: null
    });

    useEffect(() => {
        // Initialize Nice Select
        const $ = window.$;
        const select = $('select:not(.ignore)');
        select.niceSelect();

        // Sync Nice Select changes with React state
        select.on('change', (e) => {
            const { name, value } = e.target;
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        });

        // Cleanup on unmount
        return () => {
            select.off('change');
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus({ submitting: true, success: false, error: null });
        
        try {
            const templateParams = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                phone: formData.phone,
                email: formData.email,
                user_type: formData.user_type,
                template_content: 'first|last|number|email|user_type\n{{first_name}}|{{last_name}}|{{phone}}|{{email}}|{{user_type}}'
            };

            await emailjs.send(
                'service_d9xbcrk', // Replace with your EmailJS service ID
                'template_8uwlljp', // Replace with your EmailJS template ID
                templateParams,
                'UyR5MLE0MXHwXVLni' // Replace with your EmailJS public key
            );

            setStatus({
                submitting: false,
                success: true,
                error: null
            });

            // Clear form after successful submission
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                user_type: ''
            });

            // Reset select input
            const $ = window.$;
            $('select').val('').niceSelect('update');

            setTimeout(() => {
                navigate('/');
            }, 2000);

        } catch (error) {
            console.error('Submission error:', error);
            setStatus({
                submitting: false,
                success: false,
                error: 'Failed to submit form. Please try again.'
            });
        }
    };

    return (
        <>
            <Breadcrumb 
                heading="Create Account"
                currentPage="Sign Up" 
            />
            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <div className="sec-title__tagline">
                                        <h6>Join AgricHub</h6>
                                        <span className="right"></span>
                                    </div>
                                    <h2 className="sec-title__title">Create Your Account</h2>
                                </div>

                                <form className="default-form2" onSubmit={handleSubmit}>
                                    {status.error && (
                                        <div className="alert alert-danger">
                                            {status.error}
                                        </div>
                                    )}
                                    
                                    {status.success && (
                                        <div className="alert alert-success">
                                            Registration successful! Redirecting to home page...
                                        </div>
                                    )}

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="input-box">
                                                <div className="select-box">
                                                    <select 
                                                        className="selectmenu wide"
                                                        name="user_type"
                                                        value={formData.user_type}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="">Select Category...</option>
                                                        <option value="customer">Customer</option>
                                                        <option value="farmer">Farmer</option>
                                                        <option value="driver">Driver</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="First Name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="Last Name"
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Phone Number"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="contact-one__form-btn">
                                                <button
                                                    type="submit"
                                                    className="thm-btn"
                                                    disabled={status.submitting}
                                                >
                                                    <span className="txt">
                                                        {status.submitting ? 'Submitting...' : 'Create Account'}
                                                    </span>
                                                    &nbsp;&nbsp;<i className="fa fa-angle-double-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="contact-page__img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/resources/contact-3.jpeg"} alt="Contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterOne />
        </>
    );
};

export default SignUpForm;
