import React from 'react';
import { Link } from 'react-router-dom';

export default class AboutOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="about-one">
                    <div className="shape1 float-bob-y"><img src={publicUrl+"assets/images/shapes/about-v1-shape1.png"} alt="#" /></div>
                    <div className="shape2 float-bob-y"><img src={publicUrl+"assets/images/shapes/about-v1-shape2.png"} alt="#" /></div>
                    <div className="shape3"></div>
                    <div className="about-one__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/about/team.jpeg)'}}>
                        <div className="about-one__bg-content">
                            <div className="img-box">
                                <div className="inner">
                                    <img src={publicUrl+"assets/images/testimonial/cebo.jpg"} alt="#" />
                                </div>
                                <div className="content-box">
                                    <div className="icon-box">
                                        <span className="icon-butterflies"></span>
                                    </div>

                                    <div className="text-box">
                                        <h2>The Platform's <br /> Founder</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6"></div>
                            {/* Start About One Content */}
                            <div className="col-xl-6">
                                <div className="about-one__content">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>About AgricHub </h6>
                                            <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Our Mission</h2>
                                    </div>

                                    <div className="about-one__content-text1">
                                        <p className="text1">Connecting local farmers with consumers, delivering fresh, sustainable produce while supporting fair trade and fostering community connections.</p>
                                        <p className="text2">We aim to make quality farm-to-table products accessible, while supporting the growth and prosperity of our agricultural communities. </p>
                                    </div>

                                    <div className="about-one__content-text2">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="about-one__content-text2-single">
                                                    <h3> <span className="icon-confirmation"></span> Online Farmer's Market</h3>
                                                    <p>Explore and buy produce and livestock from local farmers.</p>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="about-one__content-text2-single">
                                                    <h3> <span className="icon-confirmation"></span> Community Supported</h3>
                                                    <p>This platform is endorsed by agricultural experts.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="about-one__content-bottom">
                                        <div className="btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/signup`} className="thm-btn">
                                                <span className="txt">Start OnBoarding</span>
                                                &nbsp;&nbsp;<i className="fa fa-angle-double-right"></i>
                                            </Link>
                                        </div>

                                        <div className="client-info">
                                            <div className="img-box">
                                                <a href="https://jskwwc.com/digital-agency/">
                                                    <img src={publicUrl+"assets/images/about/jskwwc-1.png"} alt="#" />
                                                </a>
                                            </div>

                                            <div className="text-box">
                                                <p><b>Platform Developer</b></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* End About One Content */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}