import React from 'react';
import {Link} from "react-router-dom";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import LogoTwo from './LogoTwo';


export default class HeaderTwo extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <header className="main-header main-header-one main-header-two">

                    {/* Start Main Header One Bottom */}
                    <div className="main-header-one__bottom">
                        <div className="main-header-two__bottom-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/main-header-v2-bg.png)'}}></div>
                        <div className="main-header-one__bottom-inner">
                            <nav className="main-menu main-menu-one">
                                <div className="main-menu__wrapper clearfix">
                                    
                                </div>
                            </nav>
                        </div>
                    </div>
                    {/* End Main Header Two Bottom */}
                </header>
            </>
        )
    }
}