import React from 'react';
import { Link } from 'react-router-dom';

export default class ServiceOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-one">
                    <div className="gradient-bg"></div>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Platform Features</h6>
                                <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">What We Offer</h2>
                        </div>

                        <div className="row">
                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="services-one__single">
                                    <div className="shape1"><img src={publicUrl+"assets/images/shapes/services-v1-shape2.png"} alt="#" /></div>
                                    <div className="shape2"><img src={publicUrl+"assets/images/shapes/services-v1-shape3.png"} alt="#" /></div>
                                    <div className="shape3"><img src={publicUrl+"assets/images/shapes/services-v1-shape4.png"} alt="#" /></div>
                                    <div className="services-one__single-img">
                                        <div className="services-one__single-img-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/services-v1-shape1.png)'}}></div>
                                        <div className="overlay-icon">
                                            <div className="icon-box">
                                                <span className="icon-gardening-1"></span>
                                            </div>
                                        </div>
                                        <img src={publicUrl+"assets/images/services/services-1.jpeg"} alt="#" />
                                    </div>

                                    <div className="services-one__single-content text-center">
                                        <h2>Marketplace Produce Discovery </h2>
                                        <p>Enables users to explore and access fresh produce listed by farmers or sellers.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
                                <div className="services-one__single">
                                    <div className="shape1"><img src={publicUrl+"assets/images/shapes/services-v1-shape2.png"} alt="#" /></div>
                                    <div className="shape2"><img src={publicUrl+"assets/images/shapes/services-v1-shape3.png"} alt="#" /></div>
                                    <div className="shape3"><img src={publicUrl+"assets/images/shapes/services-v1-shape4.png"} alt="#" /></div>
                                    <div className="services-one__single-img">
                                        <div className="services-one__single-img-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/services-v1-shape1.png)'}}></div>
                                        <div className="overlay-icon">
                                            <div className="icon-box">
                                                <span className="icon-gardening-1"></span>
                                            </div>
                                        </div>
                                        <img src={publicUrl+"assets/images/services/services-2.jpeg"} alt="#" />
                                    </div>

                                    <div className="services-one__single-content text-center">
                                        <h2>Farm Profiles and Produce  </h2>
                                        <p>Showcase farmers and their offerings, connecting users with fresh, locally sourced goods.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="services-one__single">
                                    <div className="shape1"><img src={publicUrl+"assets/images/shapes/services-v1-shape2.png"} alt="#" /></div>
                                    <div className="shape2"><img src={publicUrl+"assets/images/shapes/services-v1-shape3.png"} alt="#" /></div>
                                    <div className="shape3"><img src={publicUrl+"assets/images/shapes/services-v1-shape4.png"} alt="#" /></div>
                                    <div className="services-one__single-img">
                                        <div className="services-one__single-img-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/services-v1-shape1.png)'}}></div>
                                        <div className="overlay-icon">
                                            <div className="icon-box">
                                                <span className="icon-gardening-1"></span>
                                            </div>
                                        </div>
                                        <img src={publicUrl+"assets/images/services/services-3.jpeg"} alt="#" />
                                    </div>

                                    <div className="services-one__single-content text-center">
                                        <h2>Realtime Inventory Tracking </h2>
                                        <p>Tracking monitors and updates stock levels instantly for accurate availability.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInRight" data-wow-delay="100ms" data-wow-duration="1000ms">
                                <div className="services-one__single">
                                    <div className="shape1"><img src={publicUrl+"assets/images/shapes/services-v1-shape2.png"} alt="#" /></div>
                                    <div className="shape2"><img src={publicUrl+"assets/images/shapes/services-v1-shape3.png"} alt="#" /></div>
                                    <div className="shape3"><img src={publicUrl+"assets/images/shapes/services-v1-shape4.png"} alt="#" /></div>
                                    <div className="services-one__single-img">
                                        <div className="services-one__single-img-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/services-v1-shape1.png)'}}></div>
                                        <div className="overlay-icon">
                                            <div className="icon-box">
                                                <span className="icon-gardening-1"></span>
                                            </div>
                                        </div>
                                        <img src={publicUrl+"assets/images/services/services-4.jpeg"} alt="#" />
                                    </div>

                                    <div className="services-one__single-content text-center">
                                        <h2>Transparent pricing <br/>and listings</h2>
                                        <p>Provide clear, upfront costs and detailed produce/livestock information.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}