import React from 'react';
import emailjs from '@emailjs/browser';

export default class ContactOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form_name: '',
            form_email: '',
            form_subject: '',
            message: '',
            category: '',
            sending: false,
            error: null,
            success: false
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const $ = window.$;
        
        // Initialize niceSelect and handle its change event
        const select = $('select:not(.ignore)');
        select.niceSelect();
        
        // Sync niceSelect changes with React state
        select.on('change', (e) => {
            this.setState({ category: e.target.value });
        });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!this.state.category) {
            this.setState({ error: 'Please select a category' });
            return;
        }

        this.setState({ sending: true, error: null });

        try {
            // Replace these with your actual EmailJS service ID, template ID, and public key
            const result = await emailjs.sendForm(
                'service_d9xbcrk',
                'template_2pxmada',
                this.formRef.current,
                'UyR5MLE0MXHwXVLni'
            );

            if (result.text === 'OK') {
                this.setState({
                    success: true,
                    form_name: '',
                    form_email: '',
                    form_subject: '',
                    message: '',
                    category: ''
                });
                
                // Reset niceSelect to default
                const $ = window.$;
                $('select:not(.ignore)').val('').niceSelect('update');
            }
        } catch (error) {
            this.setState({ error: 'Failed to send message. Please try again.' });
        } finally {
            this.setState({ sending: false });
        }
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        const { sending, error, success } = this.state;

        return (
            <>
                <section className="contact-one">
                    <div className="contact-one__img wow slideInRight" data-wow-delay="500ms" data-wow-duration="2500ms">
                        <img className="float-bob-y" src={publicUrl+"assets/images/resources/contact-1.jpeg"} alt="#" />
                    </div>
                    <div className="shape1"><img src={publicUrl+"assets/images/shapes/contact-v1-shape1.png"} alt="#" /></div>
                    <div className="shape2"><img src={publicUrl+"assets/images/shapes/contact-v1-shape2.png"} alt="#" /></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="contact-one__form">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>Contact Us</h6>
                                            <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Have Any More <br /> Queries?</h2>
                                    </div>

                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {success && <div className="alert alert-success">Message sent successfully!</div>}

                                    <form 
                                        ref={this.formRef}
                                        id="contact-form" 
                                        name="contact_form" 
                                        className="default-form2" 
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <input 
                                                        type="text" 
                                                        name="form_name" 
                                                        value={this.state.form_name}
                                                        onChange={this.handleInputChange}
                                                        placeholder="Name" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <input 
                                                        type="email" 
                                                        name="form_email" 
                                                        value={this.state.form_email}
                                                        onChange={this.handleInputChange}
                                                        placeholder="Email" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <input 
                                                        type="text" 
                                                        name="form_subject" 
                                                        value={this.state.form_subject}
                                                        onChange={this.handleInputChange}
                                                        placeholder="Subject" 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <input 
                                                        type="text" 
                                                        name="message" 
                                                        value={this.state.message}
                                                        onChange={this.handleInputChange}
                                                        placeholder="Message" 
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <div className="select-box">
                                                        <select 
                                                            className="selectmenu wide"
                                                            name="category"
                                                            value={this.state.category}
                                                        >
                                                            <option value="">Select Category</option>
                                                            <option value="Farmer">Farmer</option>
                                                            <option value="Customer">Customer</option>
                                                            <option value="Courier">Courier</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="contact-one__form-btn">
                                                    <button 
                                                        className="thm-btn" 
                                                        type="submit" 
                                                        disabled={sending}
                                                    >
                                                        <span className="txt">
                                                            {sending ? 'Sending...' : 'Send'}
                                                        </span>
                                                        <i className="icon-double-chevron"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
